import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import Footer from "./footer";
import Promotion from "./promotion";
import ProductLogoShowcase from "./product-logo-showcase";
import "./layout.css";
import "../styles/index.scss";
import "../styles/output.css";
import "../styles/custom.scss";
import "../styles/global.css";

import { adRefCity } from "../helpers/adRefTracking";
import Navbar from "./Navbar";

const Layout = ({ children, pageProps, footerLogoShowcase, isHomePage, customSchema, headerTransparent, headerShadow }) => {
  if (typeof window !== "undefined" && window.localStorage && !window.localStorage.getItem("landingURL")) {
    window.localStorage.setItem("landingURL", pageProps.location.href);
    window.localStorage.setItem("landingDate", new Date());
  }
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              description
              companyInfo {
                phone
                email
                addressLine1
                addressLine2
                city
                state
                zip
                license
              }
            }
          }
          allContentfulNavigation(filter: { name: { eq: "Main Navigation" } }) {
            edges {
              node {
                id
                name
                menuItems {
                  id
                  name
                  title
                  url
                  openInNewWindow
                  submenu {
                    id
                    name
                    menuItems {
                      id
                      name
                      title
                      url
                      openInNewWindow
                    }
                  }
                }
                campaign
              }
            }
          }
        }
      `}
      render={(data) => (
        <>
          <Navbar
            siteTitle={data.site.siteMetadata.title}
            siteDescription={adRefCity(data.site.siteMetadata.description)}
            companyInfo={data.site.siteMetadata.companyInfo}
            pageProps={pageProps}
            headerTransparent={headerTransparent}
            shadow={headerShadow}
          />

          {/* <Header
            siteTitle={data.site.siteMetadata.title}
            siteDescription={adRefCity(data.site.siteMetadata.description)}
            companyInfo={data.site.siteMetadata.companyInfo}
            mainNavigation={
              data.allContentfulNavigation.edges.filter(
                ({ node: { campaign } }) =>
                  campaign.length > 0 ? campaign[0] === 'default' : false
              )[0].node.menuItems
            }
            menus={data.allContentfulNavigation.edges}
            pageProps={pageProps}
          /> */}

          <div className="layout-container">{children}</div>
          <ProductLogoShowcase footerLogoShowcase={footerLogoShowcase} />
          <Footer isHomePage={isHomePage} />
          <Promotion />
          {!customSchema && (
            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{
                __html: `
              {
                "@id": "https://www.rosevilleautotint.com/#organization",
                "@context": "https://www.schema.org",
                "@type": "AutomotiveBusiness",
                "name": "A+ Window Tinting",
                "url": "https://www.rosevilleautotint.com/",
                "logo": "https://images.ctfassets.net/wxy0gr0m99ho/4aHQaJG0RX0T1jPOh0KrT1/0e33c857027f04f5246ed5e66a188a0d/wch-thumb-2.jpg",
                "image": "https://images.ctfassets.net/wxy0gr0m99ho/4aHQaJG0RX0T1jPOh0KrT1/0e33c857027f04f5246ed5e66a188a0d/wch-thumb-2.jpg",
                "description": "A+ Window Tinting is a professional window tinting service in Roseville, CA. We offer a variety of services including window tinting, paint protection film, and ceramic coating. We are a 3M authorized dealer and offer a lifetime warranty on all of our services. Contact us today for a free quote!",
                "address": {
                    "@type": "PostalAddress", 
                    "streetAddress": "7311 Galilee Rd Ste 195",
                    "addressLocality": "Roseville",
                    "addressRegion": "CA",
                    "postalCode": "95678",
                    "addressCountry": "USA"
                },
  
                "openingHours": "Mo, Tu, We, Th, Fr 08:00-18:00",
                "contactPoint": {
                    "@type": "ContactPoint",
                    "contactType": "sales",
                    "telephone": "+1 916-308-6022"
                }
            }`,
              }}
            />
          )}
        </>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

//classes for tailwind to include when doing purge
// pr-2
